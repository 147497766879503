



















import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import DocumentMixin from '@/mixins/document';

@Component
export default class InputDocument extends Mixins(DocumentMixin) {
    @Prop({ type: String }) value!: string;
    @Prop({ type: String }) vid!: string;
    @Prop({ type: String, default: 'span' }) tag!: string;
    @Prop({ type: String }) name!: string;
    @Prop({ type: Object, default: () => ({}) }) readonly rules!: string;
    @Prop({ type: String, default: 'dni' }) typeDoc!: string;
    @Prop({ type: String }) classInput!: string;
    currentRules = { min: 8 };

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }

    created() {
        this.currentRules = Object.assign(this.currentRules, this.rules);
        this.$set(
            this.currentRules,
            'min',
            this.changeDocumentType(this.typeDoc)
        );
    }

    @Watch('rules')
    handleRuleChange(): void {
        this.currentRules = Object.assign(this.currentRules, this.rules);
    }

    @Watch('typeDoc')
    handleDocumentType(value: string): void {
        this.$set(this.currentRules, 'min', this.changeDocumentType(value));
        this.currentValue = '';
    }

    handleDocumentNumber(value: string) {
        this.currentValue = this.changeDocumentNumber({
            value,
            maxlengthDocument: this.currentRules.min
        });
    }
}
